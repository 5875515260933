<!-- 使用记录 -->

<template>
  <w-dialog class="usage-record" ref="usageRef" title="使用记录" width="50%" top="5vh" :hideFooter="true">
    <div class="usage-content" v-loading="usageLoading">
      <common-table ref="tableRef" tableHeight="calc(100vh - 550px)" :ischeck="false"
        :apiName="ScenicApi.getMemberCardLogList" :columns="tableColumns"
        :extraParame="{ mcd_id: currentMember.mcd_id }" @onRebate="onRebate"></common-table>
    </div>
  </w-dialog>
</template>

<script>
import { ref, nextTick, } from "vue";
import { ScenicApi, } from "@/plugins/api.js";
import { ElMessage, ElMessageBox, } from "element-plus";

export default {
  emits: ["onConfirmBtn"],
  setup(props, { emit }) {
    const usageRef = ref(null);  // 使用记录对象
    const usageLoading = ref(false);  // 加载状态
    const currentMember = ref({});  // 当前行会员数据
    const tableRef = ref(null);  // 表格对象
    const tableColumns = ref([  // 表格配置数据
      {
        prop: "mcl_date",
        label: "使用日期",
      },
      {
        prop: "mcl_count",
        label: "使用次数",
      },
      {
        prop: "mcl_ctime",
        label: "记录时间",
      },
      {
        type: "operation",
        prop: "",
        label: "操作",
        minWidth: 110,
        bottons: [
          {
            name: "返销",
            action: "onRebate",
            token: "m_b9lusQYmGy8XRlMWzUwp51IWfUf7",
            className: "error-font-btn",
          },
        ],
      },
    ]);
    /**
     * 
     * 打开弹框
     * 
     * **/
    const openDialog = (row) => {
      currentMember.value = row;
      usageRef.value.show();
      nextTick(() => {
        tableRef.value.tableLoad();
      });
    }
    /**
     * 
     * 点击返销按钮
     * 
     * */
    const onRebate = (item) => {
      ElMessageBox.confirm(
        `您确认要进行"返销"操作？`,
        '温馨提示',
        { confirmButtonText: '确认', cancelButtonText: '取消', }
      )
        .then(() => {
          ScenicApi.memberCardLogRevoke({ mcl_id: item.mcl_id }).then((res) => {
            if (res.Code === 200) {
              tableRef.value.tableLoad();
              emit("onConfirmBtn");
              ElMessage.success('操作成功！');
            } else {
              ElMessage.error(res.Message);
            }
          });
        })
        .catch(() => { })
    }

    return {
      usageRef,
      usageLoading,
      openDialog,
      currentMember,
      tableColumns,
      tableRef,
      ScenicApi,
      onRebate,
    };
  },
};
</script>

<style lang="scss">
.usage-record {}
</style>